import React from 'react'
import './Skills.css'

import HTMLLogo from '../images/skill logos/html5-svgrepo-com.svg'
import CSSLogo from '../images/skill logos/css3-shiled-svgrepo-com.svg'
import JSLogo from '../images/skill logos/js-svgrepo-com.svg'
import ReactLogo from '../images/skill logos/react-svgrepo-com.svg'
import UnityLogo from '../images/skill logos/unity-svgrepo-com.svg'
import CSharpLogo from '../images/skill logos/language-csharp-svgrepo-com.svg'
import FigmaLogo from '../images/skill logos/figma-svgrepo-com.svg'

import GithubLogo from '../images/skill logos/github-svgrepo-com.svg'
import GitLogo from '../images/skill logos/git-svgrepo-com.svg'
import GodotLogo from '../images/skill logos/godot-engine-svgrepo-com.svg'
import PythonLogo from '../images/skill logos/python-svgrepo-com.svg'
import CLogo from '../images/skill logos/c-svgrepo-com.svg'
import JavaLogo from '../images/skill logos/java-svgrepo-com.svg'
import AudacityLogo from '../images/skill logos/audacity-svgrepo-com.svg'
import FirebaseLogo from '../images/skill logos/firebase-svgrepo-com.svg'
import RestLogo from '../images/skill logos/server-svgrepo-com.svg'
import DataLogo from '../images/skill logos/diagram-structure-svgrepo-com.svg'
import OfficeLogo from '../images/skill logos/office365-svgrepo-com.svg'

import AngularLogo from '../images/skill logos/angular-svgrepo-com.svg'
import SQLLogo from '../images/skill logos/db-svgrepo-com.svg'
import CPlusPlusrLogo from '../images/skill logos/cplusplus-svgrepo-com.svg'
import RLogo from '../images/skill logos/r-svgrepo-com.svg'

function Skills() {

  return (
    <div id="skills" className='section-invisible'>
        <h1>My Skills</h1>
        <h3><i>Advanced Knowledge</i></h3>
        <div className='skills-container'>
            <div className='logo-container'><div className='skills-logo-box'><img src={HTMLLogo} alt='HTML Logo' className='skills-logo'/></div><h4 className='skills-text'>HTML</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={CSSLogo} alt='CSS Logo' className='skills-logo-big'/></div><h4 className='skills-text'>CSS</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={JSLogo} alt='JavaScript Logo' className='skills-logo'/></div><h4 className='skills-text'>JavaScript</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={ReactLogo} alt='React Logo' className='skills-logo'/></div><h4 className='skills-text'>React</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={GodotLogo} alt='Godot Logo' className='skills-logo'/></div><h4 className='skills-text'>Godot</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={UnityLogo} alt='Unity Logo' className='skills-logo'/></div><h4 className='skills-text'>Unity</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={CSharpLogo} alt='C# Logo' className='skills-logo-big'/></div><h4 className='skills-text'>C#</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={FigmaLogo} alt='Figma Logo' className='skills-logo'/></div><h4 className='skills-text'>Figma</h4></div>
        </div>
        <br />
        <h3><i>Intermediate Knowledge</i></h3>
        <div className='skills-container'>
            <div className='logo-container'><div className='skills-logo-box'><img src={GithubLogo} alt='Github Logo' className='skills-logo'/></div><h4 className='skills-text'>Github</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={GitLogo} alt='Git Logo' className='skills-logo'/></div><h4 className='skills-text'>Git</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={PythonLogo} alt='Python Logo' className='skills-logo'/></div><h4 className='skills-text'>Python</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={CLogo} alt='C Logo' className='skills-logo'/></div><h4 className='skills-text'>C</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={JavaLogo} alt='Java Logo' className='skills-logo'/></div><h4 className='skills-text'>Java</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={AudacityLogo} alt='Audacity Logo' className='skills-logo'/></div><h4 className='skills-text'>Audacity</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={FirebaseLogo} alt='Firebase Logo' className='skills-logo'/></div><h4 className='skills-text'>Firebase</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={RestLogo} alt='REST API Logo' className='skills-logo'/></div><h4 className='skills-text'>REST APIs</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={DataLogo} alt='Data Structures Logo' className='skills-logo'/></div><h4 className='skills-text'>Data Structures</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={OfficeLogo} alt='Microsoft Office Logo' className='skills-logo'/></div><h4 className='skills-text'>Microsoft Office</h4></div>
        </div>
        <br />
        <h3><i>Cursory Knowledge</i></h3>
        <div className='skills-container'>
            <div className='logo-container'><div className='skills-logo-box'><img src={AngularLogo} alt='Angular Logo' className='skills-logo'/></div><h4 className='skills-text'>Angular</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={SQLLogo} alt='SQL Logo' className='skills-logo-big'/></div><h4 className='skills-text'>SQL</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={CPlusPlusrLogo} alt='C++ Logo' className='skills-logo'/></div><h4 className='skills-text'>C++</h4></div>
            <div className='logo-container'><div className='skills-logo-box'><img src={RLogo} alt='R Logo' className='skills-logo'/></div><h4 className='skills-text'>R</h4></div>
        </div>
    </div>
  )
}

export default Skills