import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import ResumeFile from "../../documents/2022 Resume.pdf"
import Mountain from '../../images/Portfolio Mountain Background-Vector.svg'
import './Resume.css'

function Resume() {
  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflowY = "hidden";
  }, [])

  return (
    <div id="resume">
      <Link to="/">
        <h3 id="resume-text">&larr; &emsp; Back to Portfolio</h3>
      </Link>
      <img src={Mountain} id="resume-mountain" alt="mountain background"/>
      <Document file={ResumeFile} onLoadSuccess={() => {console.log("Successful Load")}}>
          <Page pageNumber={1}/>
      </Document>
    </div>
  )
}

export default Resume