import React from 'react'
import LinkLogo from '../images/link-svgrepo-com.svg'

import './ProjectBox.css'

function ProjectBox({ thumbnail, link, title, madewith, description }) {
  let openPopup = (() => {
    let popup = document.getElementById("popup");
    if(popup.className === "popup-invisible") {
      popup.className = "popup-visible"
    }
  });

  return (
    <div id="project-box">
        <div id="project-box-left">
          <img src={thumbnail} alt={title} id="project-box-thumbnail" onClick={openPopup}/>
        </div>
        <div id="project-box-right">
            <div id="project-title-box">
              <h2 id="project-title">{title}</h2>
              <a href={link} target="_blank" rel="noreferrer"><img src={LinkLogo} id="project-box-link" alt="Link"/></a>
            </div>
            <h4 className="project-description"><i>{madewith}</i></h4>
            <h4 className="project-description">{description}</h4>
        </div>
    </div>
  )
}

export default ProjectBox