import React, { useRef } from 'react'
import './HomePageBottom.css'
import emailjs from '@emailjs/browser';


import GithubLogo from '../images/skill logos/github-svgrepo-com.svg'
import CodepenLogo from '../images/other logos/codepen-svgrepo-com.svg'
import ItchLogo from '../images/other logos/itch-io-svgrepo-com.svg'

function HomePageBottom() {
    
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm(process.env.REACT_APP_EMAIL_ID, 'portfolio_contact_form', form.current, process.env.REACT_APP_PUBLIC_KEY)
        .then((result) => {
          console.log(result.text);
          document.getElementById("form-submit-text").innerText = "Message Sent!"
          document.getElementById("form-submit-text").className="submit-text-visible-success";
          setTimeout(() => {
            document.getElementById("form-submit-text").className="submit-text-invisible";
          }, 10000)
      }, (error) => {
          console.log(error.text);
          document.getElementById("form-submit-text").innerText = "Message Not Sent. Please Try Again."
          document.getElementById("form-submit-text").className="submit-text-visible-failure";
      });
      };

  return (
    <div id="home-page-bottom" className="section-invisible">
        <h1 id="contact-form-title">Contact Me</h1>
        <form ref={form} onSubmit={sendEmail}>
        <div id="contact-form-inputs">
            <div>
                <label htmlFor="form-name"><h3>Name</h3></label>
                <input type="text" id="form-name" name="from_name" className="form-box form-box-short" required/>
            </div>
            <div>
                <label htmlFor="form-email"><h3 id="form-email-label">Email</h3></label>
                <input type="text" id="form-email" name="user_email" className="form-box form-box-short" required/>
            </div>
            <div className="form-big-container">
                <label htmlFor="form-subject"><h3>Subject</h3></label>
                <input type="text" id="form-subject" name="subject" className="form-box form-box-long"/>
            </div>
            <div className="form-big-container">
                <label htmlFor="form-message"><h3>Message</h3></label>
                <textarea rows="9" id="form-message" name="message" className="form-box form-box-long"/>
            </div>
            <h3><i id="form-submit-text" className="submit-text-invisible"></i></h3>
            <input type="submit" id="form-submit" value="Submit"/>
        </div>
        </form>
        <div className="contact-divider"/>
        <div id="check-me-out-box">
            <h2>Learn more about my work</h2>
            <h3>You won't regret it!</h3>
            <div id="check-me-out-logos">
            <div className="check-me-out-logo-container"><a href="https://github.com/NickAtNight11" target="_blank" rel="noreferrer"><img src={GithubLogo} alt="GitHub" className="check-me-out-image"/></a></div>
            <div className="check-me-out-logo-container"><a href="https://codepen.io/NickWa11" target="_blank" rel="noreferrer"><img src={CodepenLogo} alt="CodePen" className="check-me-out-image"/></a></div>
            <div className="check-me-out-logo-container"><a href="https://pixel-myth-studios.itch.io/" target="_blank" rel="noreferrer"><img src={ItchLogo} alt="Itch.io" className="check-me-out-image"/></a></div>
            </div>
        </div>
        <div className="contact-divider"/>
    </div>
  )
}

export default HomePageBottom