import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import Mountain from '../../images/Portfolio Mountain Background-Vector.svg'
import AboutMe from '../AboutMe';
import Skills from '../Skills';
import ProjectBox from '../ProjectBox';
import Popup from '../Popup';
import HomePageBottom from '../HomePageBottom';

import LinkedInLogo from '../../images/linkedin-svgrepo-com.svg'
import GameLogo from '../../images/Pixel Myth Logo - Blue.png'

import CAndFThumb from '../../images/project thumbnails/C & F Thumbnail.gif'
import MarkdownThumb from '../../images/project thumbnails/Markdown Thumbnail.gif'
import DrumMachineThumb from '../../images/project thumbnails/Drum Machine Thumbnail.PNG'
import MirrorHeistThumb from '../../images/project thumbnails/Mirror Heist Title.png'
import WordsOfIllusionThumb from '../../images/project thumbnails/Words of Illusion Title.gif'
import TetroJumpThumb from '../../images/project thumbnails/Tetro Jump Thumbnail.png'

import './HomePage.css'

function HomePage() {
  let currentsection = "";
  let revealing = true;
  let tabsrevealed = 0;
  let aboutoffset = 0;
  let skilloffset = 0;
  let projectoffset = 0;
  let contactoffset = 0;

  let lighten = (() => {
    setOffsets();
    document.body.style.overflowY = "scroll";
    document.getElementById("home-blue-gradient").className = "slow-invisible";
    document.getElementById("home-yellow-gradient").className = "slow-visible";
    document.getElementById("home-page-sun").className = "sun-visible";
    currentsection = "";
    let timer = setTimeout(() => {
      animateNavbar("about-me")
      if(window.pageYOffset < aboutoffset && currentsection === "") {
        scrollToSection("about-me", "about-me-selector")
      }
      else {
        document.getElementById("about-me").className = "slow-visible";
      }
      endTimer(timer);
    }, 2500)
  });

  let animateNavbar = ((tab) => {
    tabsrevealed += 1;
    let timer = setTimeout(() => {
      document.getElementById(tab + "-tab-text").className = "navbar-text-reset"
      document.getElementById(tab + "-selector-bottom").className = "navbar-selector selector-bottom fast-visible"
      switch(tabsrevealed){
        case 1: animateNavbar("skills"); break;
        case 2: animateNavbar("projects"); break;
        case 3: animateNavbar("contact-me"); break;
        case 4: revealing = false;
                changeNavbarLight(true, true, currentsection);
                let timer2 = setTimeout(() => {
                  document.getElementById("navbar-resume-box").className = "medium-visible"
                  endTimer(timer2)
                  document.getElementById("about-me").className = "slow-visible";
                }, 500)
                break;
        default: break;
      }
      endTimer(timer)
    }, 500)
  })

  let scrollToSection = ((section, selectorsection) => {
    const nav = document.getElementById("navbar")
    changeNavbarLight(false, true, currentsection)
    changeNavbarLight(true, true, selectorsection)
    window.scroll({
      top: (document.getElementById(section).getBoundingClientRect().top + window.scrollY - nav.offsetHeight),
      behavior: "smooth"
    });
  });

  let changeNavbarLight = ((focusing, current, section) => {
    if(focusing) {
      if(!revealing && section !== "" && document.getElementById(section) != null) {
        document.getElementById(section).className = "navbar-selector selector-top fast-visible";
      }
      if(current) {
        currentsection = section
      }
    }
    else if(!focusing && section !== "" && document.getElementById(section) != null && ((!current && section !== currentsection) || (current && section !== ""))) {
      document.getElementById(section).className =  "navbar-selector selector-top fast-invisible";
    }
  });

  let setOffsets = (() => {
    aboutoffset = document.getElementById("about-me").getBoundingClientRect().top - document.body.getBoundingClientRect().top - (window.innerHeight/2)
    skilloffset = document.getElementById("skills").getBoundingClientRect().top - document.body.getBoundingClientRect().top - (window.innerHeight/2)
    projectoffset = document.getElementById("projects-title").getBoundingClientRect().top - document.body.getBoundingClientRect().top - (window.innerHeight/2)
    contactoffset = document.getElementById("contact-form-title").getBoundingClientRect().top - document.body.getBoundingClientRect().top - (window.innerHeight/2)
  });

  let checkScroll = (() => {
    if(window.pageYOffset < aboutoffset && currentsection !== "") {
      changeNavbarLight(false, true, currentsection);
      currentsection = ""
    }
    else if(window.pageYOffset >= aboutoffset && window.pageYOffset < skilloffset && currentsection !== "about-me-selector") {
      changeNavbarLight(false, true, currentsection);
      changeNavbarLight(true, true, "about-me-selector");
      document.getElementById("navbar").className = "medium-visible"
    }
    else if(window.pageYOffset >= skilloffset && window.pageYOffset < projectoffset && currentsection !== "skills-selector") {
      changeNavbarLight(false, true, currentsection);
      changeNavbarLight(true, true, "skills-selector");
      document.getElementById("skills").className = "slow-visible"
    }
    else if(window.pageYOffset >= projectoffset && window.pageYOffset < contactoffset && currentsection !== "projects-selector") {
      changeNavbarLight(false, true, currentsection);
      changeNavbarLight(true, true, "projects-selector");
      document.getElementById("projects").className = "slow-visible"
    }
    else if(window.pageYOffset >= contactoffset && currentsection !== "contact-me-selector") {
      changeNavbarLight(false, true, currentsection);
      changeNavbarLight(true, true, "contact-me-selector");
      document.getElementById("home-page-bottom").className = "slow-visible"
    }
  });

  let endTimer = ((t) => {
    clearTimeout(t);
  })
  
  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", () => checkScroll());// eslint-disable-next-line
  }, []);

  return (
    <div id="home-page">
      <Popup />
      <div id="navbar" className="invisible">
        <div id="navbar-tabs">
          <div className="navbar-tab-box">
            <div onMouseEnter={() => {changeNavbarLight(true, false, "about-me-selector")}} onMouseLeave={() => {changeNavbarLight(false, false, "about-me-selector")}} onClick={() => {scrollToSection("about-me", "about-me-selector")}} className="navbar-box-inner">
              <h3 id="about-me-tab-text" className="navbar-text-offset">About Me</h3>
              <div id="about-me-selector" className="navbar-selector selector-top fast-invisible"/>
              <div id="about-me-selector-bottom" className="navbar-selector selector-bottom invisible"/>
            </div>
          </div>
          <div className="navbar-tab-box">
            <div onMouseEnter={() => {changeNavbarLight(true, false, "skills-selector")}} onMouseLeave={() => {changeNavbarLight(false, false, "skills-selector")}} onClick={() => {scrollToSection("skills", "skills-selector")}} className="navbar-box-inner">
              <h3 id="skills-tab-text" className="navbar-text-offset">Skills</h3>
              <div id="skills-selector" className="navbar-selector selector-top fast-invisible"/>
              <div id="skills-selector-bottom" className="navbar-selector selector-bottom invisible"/>
            </div>
          </div>
          <div className="navbar-tab-box">
            <div onMouseEnter={() => {changeNavbarLight(true, false, "projects-selector")}} onMouseLeave={() => {changeNavbarLight(false, false, "projects-selector")}} onClick={() => {scrollToSection("projects-title", "projects-selector")}} className="navbar-box-inner">
              <h3 id="projects-tab-text" className="navbar-text-offset">Projects</h3>
              <div id="projects-selector" className="navbar-selector selector-top fast-invisible"/>
              <div id="projects-selector-bottom" className="navbar-selector selector-bottom invisible"/>
            </div>
          </div>
          <div className="navbar-tab-box">
            <div onMouseEnter={() => {changeNavbarLight(true, false, "contact-me-selector")}} onMouseLeave={() => {changeNavbarLight(false, false, "contact-me-selector")}} onClick={() => {scrollToSection("contact-form-title", "contact-me-selector")}} className="navbar-box-inner">
              <h3 id="contact-me-tab-text" className="navbar-text-offset">Contact Me</h3>
              <div id="contact-me-selector" className="navbar-selector selector-top fast-invisible"/>
              <div id="contact-me-selector-bottom" className="navbar-selector selector-bottom invisible"/>
            </div>
          </div>
        </div>
        <div id="navbar-resume-box" className="invisible">
          <Link to="/resume.pdf" id="navbar-resume-link">
            <div id="navbar-resume">
              <h4>Resume</h4>
            </div>
          </Link>
          <a href="https://www.linkedin.com/in/nicholas-watson-33b6811b6/" target="_blank" rel="noreferrer" id="navbar-linked-in">
            <img src={LinkedInLogo} alt="in" id="navbar-linked-in-logo"/>
          </a>
        </div>
      </div>
      <img src={Mountain} id="home-page-mountain" alt="mountain background"/>
      <div id="home-page-sun" className="sun-invisible">
        <span id="home-page-sun-outside" />
        <span id="home-page-sun-inside" />
      </div>
      <div id="home-blue-gradient" className="slow-visible"/>
      <div id="home-yellow-gradient" className="slow-invisible"/>
      <div id="home-page-title">
          <h1>Nick Watson</h1>
          <h3><i>Web Developer, Game Designer, Professional Learner</i></h3>
          <div id="home-page-get-started" onClick={lighten}>
              <h3>Get Started</h3>
          </div>
      </div>
      <AboutMe />
      <Skills />
      <div id="projects" className="section-invisible">
        <h1 id="projects-title">Projects</h1>
        <ProjectBox thumbnail={CAndFThumb} link="https://candfcnc.com/" title="C & F CNC" madewith="HTML, CSS, React, Firebase API and Storage" description="Designed as a promotional website for a small woodworking business, this application provides pricing information, a gallery, and a contact form for prospective customers. A separate admin interface is also in place to add, edit, and delete gallery photos using Google Firebase's API and Database services."/>
        <ProjectBox thumbnail={MarkdownThumb} link="https://nickatnight11.github.io/markdown-previewer/" title="GitHub Markdown Previewer" madewith="HTML, CSS, React, Marked-React" description="Using React's Marked library, this application provides an easy translation tool between plaintext and GitHub's Markdown Language. It provides a useful default template with the language's most popular rules, and is mobile responsive."/>
        <ProjectBox thumbnail={DrumMachineThumb} link="https://nickatnight11.github.io/drum-machine/" title="Drum Machine" madewith="HTML, CSS, React, Howler.js" description="This application uses JavaScript's Howler library to integrate sound clips into this interactive drum machine. Complete with 9 pads and an adjustable volume slider, users can create beats using both mouse and key inputs."/>
        <img src={GameLogo} alt="Pixel Myth Studios" id="pixel-myth-logo"/>
        <ProjectBox thumbnail={TetroJumpThumb} link="https://pixel-myth-studios.itch.io/tetro-jump" title="Tetro Jump" madewith="Godot, Aseprite, Beepbox" description="Tetro Jump is a game which combines the physics-based mechanics of platfomers with the strategic block-stacking of Tetris. Navigate through a world of dreams by overcoming obstacles in Jump Mode and by manipulating the structure of each level in Block Mode."/>
        <ProjectBox thumbnail={WordsOfIllusionThumb} link="https://pixel-myth-studios.itch.io/words-of-illusion" title="Words of Illusion" madewith="Unity, C#, Aseprite, Reaper" description="Set inside a top-secret labratory, Words of Illusion follows the trials of a small robot with a unique ability- Complete Erasure. Solve brain-bending puzzles by typing in the names of objects in the levels and erasing them from existence. But be careful what you decide to delete; sometimes the wrong move can lead to unforeseen consequences."/>
        <ProjectBox thumbnail={MirrorHeistThumb} link="https://pixel-myth-studios.itch.io/mirror-heist" title="Mirror Heist" madewith="Unity, C#, Aseprite, Beepbox" description="Mirror Heist is a puzzle-based platformer game with a sci-fi aesthetic. Trapped aboard an enemy ship, players must traverse the floor, walls, and ceiling to solve puzzles. By pushing the mirrors scattered throughout the levels, players can deflect enemy blaster shots in a gambit to turn their own firepower against them."/>
      </div>
      <HomePageBottom />
    </div>
  )
}

export default HomePage