import React, { useState, useEffect, useCallback } from 'react'

import CAndF1 from '../images/project thumbnails/C & F Thumbnail.gif'
import CAndF2 from '../images/slideshow pictures/C & F Screenshot 1.gif'
import CAndF3 from '../images/slideshow pictures/C & F Screenshot 2.PNG'
import CAndF4 from '../images/slideshow pictures/C & F Screenshot 3.PNG'
import CAndF5 from '../images/slideshow pictures/C & F Screenshot 4.gif'

import Markdown1 from '../images/project thumbnails/Markdown Thumbnail.gif'
import Markdown2 from '../images/slideshow pictures/Markdown Screenshot 1.gif'

import DrumMachine1 from '../images/project thumbnails/Drum Machine Thumbnail.PNG'

import MirrorHeist1 from '../images/project thumbnails/Mirror Heist Title.png'
import MirrorHeist2 from '../images/slideshow pictures/Mirror Heist Screenshot 1.PNG'
import MirrorHeist3 from '../images/slideshow pictures/Mirror Heist Screenshot 2.PNG'
import MirrorHeist4 from '../images/slideshow pictures/Mirror Heist Screenshot 3.PNG'

import WordsOfIllusion1 from '../images/project thumbnails/Words of Illusion Title.gif'
import WordsOfIllusion2 from '../images/slideshow pictures/Words of Illusion Screenshot 1.png'
import WordsOfIllusion3 from '../images/slideshow pictures/Words of Illusion Screenshot 2.png'
import WordsOfIllusion4 from '../images/slideshow pictures/Words of Illusion Screenshot 3.png'

import TetroJump1 from '../images/project thumbnails/Tetro Jump Thumbnail.png'
import TetroJump2 from '../images/slideshow pictures/Tetro Jump Screenshot 1.gif'
import TetroJump3 from '../images/slideshow pictures/Tetro Jump Screenshot 2.gif'
import TetroJump4 from '../images/slideshow pictures/Tetro Jump Screenshot 3.PNG'

import './Popup.css'

function Popup() {
    const [picIndex, setPicIndex] = useState(0);
    const [currentPics, setCurrentPics] = useState([]);

    

    let closePopup = (() => {
        let popup = document.getElementById("popup");
        if(popup.className === "popup-visible") {
            popup.className = "popup-invisible"
        }
    });

    const handleUserClick = useCallback((e) => {
        const cAndFPics = [CAndF1, CAndF2, CAndF3, CAndF4, CAndF5];
        const markdownPics = [Markdown1, Markdown2];
        const drumMachinePics = [DrumMachine1];
        const mirrorHeistPics = [MirrorHeist1, MirrorHeist2, MirrorHeist3, MirrorHeist4];
        const wordsOfIllusionPics = [WordsOfIllusion1, WordsOfIllusion2, WordsOfIllusion3, WordsOfIllusion4];
        const tetroJumpPics = [TetroJump1, TetroJump2, TetroJump3, TetroJump4];
        if(e.target.alt !== "" && e.target.alt !== undefined) {
            switch(e.target.alt) {
                case "C & F CNC": setCurrentPics(cAndFPics); break;
                case "GitHub Markdown Previewer": setCurrentPics(markdownPics); break;
                case "Drum Machine": setCurrentPics(drumMachinePics); break;
                case "Mirror Heist": setCurrentPics(mirrorHeistPics); break;
                case "Words of Illusion": setCurrentPics(wordsOfIllusionPics); break;
                case "Tetro Jump": setCurrentPics(tetroJumpPics); break;
                default: return;
            }
            setPicIndex(0);
        }
    }, []);
    
    useEffect(() => {
        window.addEventListener("click", handleUserClick);
        return () => {
            window.removeEventListener("click", handleUserClick);
        };
    }, [handleUserClick]);

    useEffect(() => {
        document.getElementById("left-arrow").className = "popup-icon-container popup-icon-visible";
                document.getElementById("right-arrow").className = "popup-icon-container popup-icon-visible";
        switch(picIndex){
            case 0: document.getElementById("left-arrow").className = "popup-icon-invisible"; break;
            case currentPics.length-1: document.getElementById("right-arrow").className = "popup-icon-invisible"; break;
            default: break;    
        }
        if(currentPics.length === 1) {
            document.getElementById("right-arrow").className = "popup-icon-invisible";
        }
    }, [picIndex, currentPics.length])

    return (
        <div id="popup" className="popup-invisible">
            <div id="left-arrow" className='popup-icon-container' onClick={() => {setPicIndex(picIndex-1)}}>
                <div className='icon-div diagonal-div-one'></div>
                <div className='icon-div diagonal-div-two arrow-bottom'></div>
            </div>
            <div id="popup-image-container">
                <div id="x-icon" className='popup-icon-container' onClick={closePopup}>
                    <div className='icon-div diagonal-div-one'></div>
                    <div className='icon-div diagonal-div-two x-bottom'></div>
                </div>
                <img src={currentPics[picIndex]} alt="Slideshow" id="popup-pic"/>
                <h2 id="popup-numbers">{picIndex+1} / {currentPics.length}</h2>
            </div>
            <div id="right-arrow" className='popup-icon-container' onClick={() => {setPicIndex(picIndex+1)}}>
                <div className='icon-div diagonal-div-two'></div>
                <div className='icon-div diagonal-div-one arrow-bottom'></div>
            </div>
        </div>
    )
}

export default Popup