import React from 'react'
import './AboutMe.css'

function AboutMe() {
  return (
    <div id="about-me" className='section-invisible'>
        <h1>About Me</h1>
        <div id="about-container">
            <div id="about-me-info">
              <h3>Hi, my name is Nick Watson, and welcome to my portfolio! 
                Here you'll find some of my favorite web and game projects that show off my best professional skills.</h3>
              <div className="about-me-descriptor">
                <h3 className="about-me-descriptor-outer">As a designer,</h3>
                <h4>My goal is to create visually appealing interfaces that stand out in users' minds and are easy to communicate with.</h4>
              </div>
              <div className="about-me-descriptor">
                <h3 className="about-me-descriptor-outer">As a developer,</h3>
                <h4>My goal is to approach the diverse needs that each user presents by crafting an efficient and satisfying UX.</h4>
              </div>
              <br />
              <h3><i>And most importantly...</i></h3>
              <div className="about-me-descriptor">
                <h3 className="about-me-descriptor-outer">As a creative,</h3>
                <h4>My goal is to help people have a fun, meaningful experience with my work.</h4>
              </div>
              <br />
              <br />
              <h3>I hope you enjoy!</h3>
            </div>
            <div id="about-me-divider"/>
            <div id="about-me-box">
              <h2>Quick Facts!</h2>
              <div id="quick-facts">
                <h3>Education:</h3>
                <h4><i>Texas Tech University</i></h4>
                <h4>Bachelor of Science in Computer Science</h4>
                <h4>Bachelor of Science in Mathematics</h4>
                <br />
                <h4><i>Free Code Camp (Certifications)</i></h4>
                <h4>Responsive Web Design</h4>
                <h4>JavaScript Algorithms/Data Structures</h4>
                <h4>Front End Development Libraries</h4>
                <br />
                <h3>Hobbies:</h3>
                <h4>Music Performance (Guitar, Low Brass) and Composition, Reading, Writing, Gaming</h4>
                <br />
                <h3>Secret Talents:</h3>
                <h4>Baking, Origami (Don't Tell Anyone)</h4>
              </div>
            </div>
        </div>
    </div>
  )
}

export default AboutMe